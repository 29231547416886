.dave-tck-vechile-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-right: 15px;
    max-width: 300px;
}

span.dave-tck-vechile-name,
span.dave-tck-vechile-model {
    text-transform: capitalize;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: end;
    white-space: nowrap;
}

@media (max-width:1250px){
    .dave-tck-vechile-info{
        max-width: 250px;
    }
}
@media (max-width:1100px){
    .dave-tck-vechile-info{
        max-width: 200px;
    }
}
@media (max-width:1100px){
    .dave-tck-vechile-info{
        max-width: 150px;
    }
}