button.dave-logout-btn {
    background: linear-gradient(89.13deg, #4F63F8 6.23%, #002783 100.02%);
    border-radius: 20px;
    border: none;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
}

button.dave-logout-btn.i-pad{
    display: none;
}

@media (max-width:1100px){
    button.dave-logout-btn{
        display: none;
    }
    button.dave-logout-btn.i-pad{
        display: inline-block;
        border-radius: 10px;
    }
}