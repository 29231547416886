span.dave-add-customer-txt {
    margin-right: 10px;
    /* color: #fff; */
}

img.dave-add-customer-img {
    max-width: 24px;
    /* width: fit-content; */
}

.dave-add-customer-btn {
    z-index: 3;
    border-radius: 20px;
    /* background: #646EE5 !important; */
    /* background: #008069 !important; */
    /* border: 2px solid #fff !important; */
    /* color: #000 !important; */
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}
.dave-add-customer-btn.i-pad{
    display: none;
}

@media (max-width:1100px){
    .dave-add-customer-btn{
        display: none;
    }
    .dave-add-customer-btn.i-pad{
        display: inline-block;
        color: #fff;
        border: 2px solid #fff;
        border-radius: 10px;
    }

}