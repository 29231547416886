body{
    height: 100%;
    position: relative;
    overflow: hidden;
}

div#root {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100dvh;
    max-height: 100vh;
    background-color: #E5E6E5;
    position: relative;
}
div#root .dave-homepage-container{
    background-color: #fff;
}
/* .dave-loader {
    height: 100vh !important;
} */
@media (max-width:1100px){
    div#root{
        /* background-color: #fff; */
        overflow: hidden;
    }
   
}
