.dave-ticket-container.col {
    display: flex;
    flex-direction: column;
    background: #F3F5FC;
    border-radius: 24px;
    /* height: 22%; */
    margin-bottom: 15px;
}
.dave-ticket-container.col.closed .dave-new-message-notification-container{
    visibility: hidden;
    pointer-events: none;
}
.dave-tickets-container.col{
    display: flex;
    flex-direction: column;
}
.dave-tickets-wrapper.col {
    padding: 10px;
    height: 100%;
    overflow: auto;
    margin-top: 5px;
}
::-webkit-scrollbar{
    width: 10px;
    background-color: var(--scroll-bg-color);
    border-radius: 0 20px 20px 0;
}
*:hover::-webkit-scrollbar-thumb{
    background-color: #737373;
    border-radius: 20px;
}
.dave-ticket-detail-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 3%;
    position: relative;
    height: 48px;
    background: #008069;
    /* background: linear-gradient(90deg, #2D309A 0%, #4F63F8 100%); */
    border-radius: 24px 24px 0px 0px;
    color: #fff;
}

span.dave-vechile-registration {
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    width: 50%;
    text-transform: uppercase;
}

span.dave-ticket-assigned {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    width: 50%;
    text-align: end;
}

.dave-ticket-vechile-detail-col {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    position: relative;
    width: fit-content;
}

span.dave-vechile-model,
span.dave-customer-mobile,
span.dave-service-type {
    color: #737373;
    text-transform: capitalize;
}

button.dave-assign-status {
    position: absolute;
    top: 30%;
    right: 3%;
    color: #fff !important;
    min-width: 75px;
    background: linear-gradient(89.13deg, #4F63F8 6.23%, #002783 100.02%);
    border: none;
    border-radius: 12px;
}

span.dave-customer-name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    word-break: break-word;
    color: #008069;
    /* color: #2D3494; */
    text-transform: capitalize;
}

.dave-ticket-detail-notification {
    min-height: 70%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    padding-right: 20px;
    padding-bottom: 12px;
    position: relative;
}

.dave-ticket-notification-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 50%;
}

.dave-ticket-notification {
    /* border: 2px solid #4F63F8; */
    border: 2px solid #000;
    /* width: 25%; */
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    /* height: 50%; */
    border-radius: 20px;
}

button.dave-new-message-btn,
button.dave-reassigned-btn {
    background: #008069 !important;
    /* background: linear-gradient(89.13deg, #4F63F8 6.23%, #002783 100.02%); */
    border-radius: 12px;
    color: #fff !important;
    border: none;
}

.dave-new-message-notification-container {
    margin-right: 10px;
}

.dave-reassigned-notification-container {
    margin-right: 10px;
}

span.dave-new-message-count {
    height: 30px;
    width: 30px;
    /* background: linear-gradient(89.13deg, #4F63F8 6.23%, #002783 100.02%); */
    background: #008069;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(30%,-30%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

img.dave-ticket-notification-img {
    /* position: absolute; */
    transform: translateY(3px);
}

@media (max-width:1100px){
    .dave-tickets-wrapper.col{
        padding: 0;
    }
    ::-webkit-scrollbar{
        display: none;
    }
}