.dave-logout-popup-container {
    position: absolute;
    height: 100vh;
    width: 100vw;
    /* background: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.dave-logout-popup-messge-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    height: 400px;
    width: 600px;
    border-radius: 10px;
    border: 3px solid #000;
    z-index: 1;
    position: relative;
}

button.dave-logout-action-btn {
    width: 300px;
    height: 72px;
    /* background: linear-gradient(90deg, #2D309A 0%, #4F63F8 100%); */
    background: #008069 !important;
    border-radius: 24px;
    margin: 10px 0;
    color: #fff !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

img.dave-close-logout-popup {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 7%;
    right: 5%;
}

.dave-logout-details-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 0 10%; */
}

span#dave-user-id,
span#dave-ccm-id,
span#dave-wm-id {
    border-radius: 10px;
    background: #E5E5E5;
    width: 50%;
    /* height: 40px; */
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    text-transform: uppercase;
    /* font-size: 1.6rem; */
    font-size: 20px;
}

.dave-logout-details-container label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
    padding-right : 10px;
    /* color: #2D3494; */
    color: #008069;
    font-size: 30px;
}

.dave-logout-details-container div {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

img.dave-logout-img {
    max-width : 36px;
}