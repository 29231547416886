.dave-notification-btn {
    margin-left: 10px;
    border-radius: 20px;
    border: none;
}

.dave-notification-btn.i-pad{
    display: none;
}

@media (max-width:1100px){
    .dave-notification-btn{
        display: none;
    }
    .dave-notification-btn.i-pad{
        display: inline-block;
        border-radius: 10px;
    }
}