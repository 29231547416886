.dave-reassigned-chat-action-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius : 10px;
    flex-wrap: nowrap;
    /* margin-bottom: 10px; */
    border: 2px solid #008069;
    height: 80px;
    bottom: 0;
    position: relative;
}

span.dave-reassigned-chat-action-text {
    text-align: center;
    color: red;
}