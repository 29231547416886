.dave-default-message-container {
    flex: none;
    display: flex;
    align-items: center;
    width: 98%;
    height: 80px;
    overflow-x: auto;
    white-space: nowrap;
    margin: 0 0 5px 0;
    position: absolute;
    bottom: 90px;
    padding: 0 10px;
}

span.dave-default-message {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    background: linear-gradient(90deg, #2D3494 0%, #4F63F8 100%);
    background: #008069;
    border-radius: 10px;
    color: #fff;
    height: 40px;
    margin-right: 10px;
    /* min-width: 30%; */
}