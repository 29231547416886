.dave-login-container {
    position: relative;
    /* top: 25%; */
    display: flex;
    justify-content: center;
}

button.dave-login.btn {
    padding: 0;
    width: -webkit-fill-available;
    max-width: 334px;
    height: 80px;
    display: flex;
    align-items: center;
    border: 2px solid #008069;
    background-color: #F3F5FC;
    outline-offset: -1px;
    border-radius: 10px;
    flex: 1;

}
.dave-login.btn .btn-text{
    font-size: 1.5rem;
    font-weight: bold;
    flex: 1;
    text-align: center;
}
.dave-login.btn .icon-container{
    background-color: #008069;
    height: 80px;
    width: 80px;
    display: grid;
    place-items: center;
    border-radius: 0 10px 10px 0;
}


img.dave-login-img {
    width: -webkit-fill-available;
}
.dave-homepage-container {
    display: grid;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

/* img.dave-footer-logo-img {
    width: -webkit-fill-available;
    max-width: 1000px;
} */

img.dave-main-logo-img {
    width: -webkit-fill-available;
}

.dave-logo-container {
    position: relative;
    top: 10%;
}

.dave-footer-container {
    /* position: fixed; */
    bottom: 0;
    display: flex;
    justify-content: center;
    height: 40vh;
}

span.dave-login-message {
    /* position: fixed; */
    bottom: 45%;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
    color: #737373;
}
@media (max-width:1100px){
    .dave-homepage-container{
        display: flex;
        justify-content: space-between;
    }
}