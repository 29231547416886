.dave-active-ticket-detail-container {
    flex: none;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
    /* background: linear-gradient(90deg, #2D309A 0%, #4F63F8 100%); */
    background: #008069;
    border-radius: 10px;
    color: #fff;
    height: 70px;
    width: 100%;
}

.dave-active-ticket-no.col {
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.dave-active-ticket-veichle-info-container {
    display: flex;
    justify-content: flex-end;
}

.dave-ticket-reassign {
    border: none !important;
    padding: 15px 15px 15px 0;
}

span.dave-active-ticket-no {
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    text-transform: uppercase;
}