.dave-status-btn {
    border-radius: 50px;
    max-width: 300px;
    width: 80%;
    margin: 10px 0;
    padding: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    outline: none;
    border: none;
}

.dave-status-name {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dave-status-number {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width:1100px){
    .dave-status-btn{
        width: auto;
        max-width: auto;
        gap: 20px;
        margin: 0;
        padding: 10px 15px;
        height: auto;
        line-height: normal;
        justify-content: space-between;
    }
}