button.dave-sort-filter {
    background: #F3F5FC !important;
    /* border: 1px solid #4F63F8 !important; */
    border: 2px solid #000 !important;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    padding: 14px;
    align-items: center;
}

.dave-ticket-search-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    gap: 10px;
    height: 60px;
}

input.dave-search-box {
    background: #F3F3F3;
    outline: none;
    /* border: 1px solid #4F63F8 !important; */
    border: 2px solid #000 !important;
    border-radius: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #737373;
    padding-left: 12%;
}

img.dave-search-icon {
    position: absolute;
    top: 30%;
    left: 4%;
    width: 25px;
}

.dave-sort-text-icon-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.dave-sort-options-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

span.dave-sort-text {
    color: #000;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
}

.dave-sort-options-container span {
    background: #646EE5;
    border-radius: 50px;
    margin: 10px 0;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
}

@media (max-width:1100px){
    .dave-ticket-search-container{
        margin-bottom: 10px;
    }
    input.dave-search-box{
        border-radius: 10px;
        font-size: 0.9rem;
        padding: 10px;
        padding-right: 10%;
        text-overflow: ellipsis;
    }
    input.dave-search-box::placeholder{
        text-overflow: ellipsis;
    }
    img.dave-search-icon{
        left: auto;
        right: 97px;
        height: 1.5rem;
        top: 50%;
        translate: 0 -50%;
    }
    button.dave-sort-filter{
        border-radius: 10px;
        width: auto !important;
    }
    .dave-sort-text-icon-container{
        gap: 10px;
    }
}