.dave-notification-container.col {
    display: flex;
    flex-direction: column;
    background: #F3F5FC;
    border-radius: 24px;
    margin: 10px 5px;
}

img.dave-notification-img {
    max-width: 26px;
}

.dave-notification-header-col {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 3%;
    position: relative;
    height: 48px;
    background: #008069;
    /* background: linear-gradient(90deg, #2D309A 0%, #4F63F8 100%); */
    border-radius: 24px 24px 0px 0px;
    color: #fff;
}

span.dave-vechile-registration {
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-transform: uppercase;
}

.dave-notification-detail-col {
    /* min-height: 70%; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 20px;
    position: relative;
}

span.dave-notification-customer-name {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    /* color: #2D3494; */
    color: #000;
    text-transform: capitalize;
    word-break: break-word;
    padding: 10px 0;
    display: flex;
    align-items: center;
}

span.dave-notification-last-message {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #838DB1;
    word-break: break-word;
    padding: 10px 0;
    padding-top: 0;
    width: 100%;
    /* overflow: hidden; */
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
}

.dave-notification-wrapper {
    height: 100%;
    overflow: auto;
}

.dave-no-notification-container {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}