* {
    font-family: 'Poppins';
}

/* html {
    font-size: 62.5%;
} */

.dave-shadow-box {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
}