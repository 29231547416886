.dave-customer-details-container {
    padding: 10px 0;
    display: flex;
    flex: 1;
    height: calc(100% - 70px);
    justify-content: space-evenly;
    background: #E5E5E5;
}
@media (max-width:1100px){
    .dave-customer-details-container{
        height: calc(100% - 100px);
        padding: 10px;
        /* background-color: #fff; */
        gap: 10px;
    }
}