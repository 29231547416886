button.dave-chat-send {
    /* background: linear-gradient(90deg, #2D309A 0%, #4F63F8 100%); */
    background: #008069 !important;
    /* border-radius: 0px 10px 10px 0px; */
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 10%; */
    z-index: 0 !important;
}

textarea.dave-user-message-typing {
    background: none;
    width: 100%;
    border: none;
    outline: none !important;
    /* border-left: none;
    border-top: none;
    border-bottom: none;
    border-right: none; */
    padding: 10px 20px;
    margin: auto;
    resize: none;
    height: fit-content;
}
textarea.dave-user-message-typing:disabled{
    visibility: hidden;
}

.dave-chat-action-container {
    display: flex;
    justify-content: space-between;
    border-radius : 10px;
    flex-wrap: nowrap;
    /* padding: 0 10px; */
    border: 2px solid #008069 !important;
    outline-offset: -2px;
    /* border: 2px solid #4F63F8; */
    height: 80px;
    position: relative;
    flex-shrink: 0;
    bottom: 0;
}

button.dave-chat-attachment-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 10%; */
    z-index: 0 !important;
}

button.dave-default-idea {
    background: linear-gradient(90deg, #2D3494 0%, #4F63F8 100%);
    border: none;
}

button.dave-default-chat {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F3F5FC !important;
    width: 10%;
    z-index: 0 !important;
}

.dave-message-and-upload-container {
    display: flex;
    /* width: 81%; */
    width: 100%;
    border-radius: 8px;
    /* border-left: 3px solid #4F63F8; */
    background: #F3F5FC;
}

.dave-chat-room-connection-error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    /* padding: 0 10px; */
    /* border: 2px solid #4F63F8; */
    border: none;
    height: 80px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: red;
    color: #fff;
    font-size: 1.6rem;
    padding: 5px 10px;
}

div.dave-chat-room-connection-error-container span.dave-chat-room-connection-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: medium;
}

span.dave-chat-room-connection-message span.dave-default-message {
    text-decoration: underline;
    height: 30px;
}