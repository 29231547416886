.dave-nugde-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    z-index: 10;
    align-items: center;
    background: rgba(27, 27, 27, 0.5);
}

div#dave-nudge-action-container {
    background: #fff;
    height: 20%;
    max-height: 200px;
    width: 60%;
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid #008069;
    border-radius: 10px;
    padding: 0 10px;
}

button#dave-hide-nudge-popup {
    background: #008069;
    width: 100px;
    border-radius: 5px;
    border: none;
    color: #fff;
    height: 30px;
}