.dave-addcustomer-popup {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: 3px solid #000;
    height: 300px;
    width: 600px;
    border-radius: 10px;
}
.dave-add-customer-popup-container.col.col-12 {
    position: absolute;
    height: 100vh;
    width: 100vw;
    /* background: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.dave-walkin-customer,
.dave-appointment-customer {
    width: 400px;
    height: 72px;
    /* background: linear-gradient(90deg, #2D309A 0%, #4F63F8 100%); */
    background: #008069 !important;
    border-radius: 24px;
    margin: 10px 0;
    color: #fff !important;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
}

img.dave-close-add-customer-popup {
    position: absolute;
    top: 5%;
    right: 3%;
    height: 20px;
    width: 20px;
}