.dave-notification-data-container {
    background: #fff;
    margin-right: 10px;
    position: relative;
}
.close-notification-btn{
    display: none;
}
@media (max-width:1100px){
    .dave-notification-data-container{
        height: 100dvh;
        width: 100dvw;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: 20;
        padding: 0 5rem;
    }
    .container-title{
        text-align: center;
    }
    .dave-notification-wrapper{
        margin-top: 1.5rem;
        height: 80%;
    }
    .close-notification-btn{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem 1.3rem;
        font-size: 20px;
        color: red;
        border: none;
        background-color: #fff;
    }
}