.dave-ticket-status-change-container.col {
    flex: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background: #F3F3F3;
    border-radius: 0 0 10px 10px;
    height: 20%;
    width: 100%;
}

button.dave-ticket-reassign-btn {
    border-radius: 10px;
    color: #F3F3F3 !important;
    width: 40%;
    max-width: 180px;
    /* background: linear-gradient(90deg, #2D309A 0%, #4F63F8 100%) !important; */
    background: #008069 !important;
}