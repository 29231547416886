.dave-active-chat-message-container {
    position: relative;
    height: 82%;
    width: 100%;
    overflow-y: auto;
    /* background: #fff; */
    border-radius: 10px;
}

.dave-sent-message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;
    gap: 5px;
    position: relative;
}

.dave-read-receit-container {
    position: absolute;
    right: 15px;
    bottom: 45px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dave-sent-message-container img.dave-read-receit-img {
    border: none;
    width: 15px;
}

.dave-sent-message-container img{
    border: 5px solid #DCF7C5;
    border-radius: 10px;
}

.dave-received-message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    word-wrap: break-word;
}
.dave-received-message-container img{
    border: 5px solid #fff;
    border-radius: 5px;
}
span.dave-sent-message-text {
    /* background: linear-gradient(90deg, #2D309A 0%, #4F63F8 100%); */
    background: #DCF7C5;
    border-radius: 20px 20px 0px 20px;
    max-width: 90%;
    padding: 5px 15px;
    color: #000;
    word-wrap: break-word;
    padding-right: 30px;
    /* color: #fff; */
}

span.dave-received-message-text {
    background: #F3F5FC;
    border-radius: 20px 20px 20px 0px;
    max-width: 90%;
    padding: 5px 15px;
}

span.dave-sent-time,
span.dave-received-time {
    color: #737373;
}

/* img.dave-sent-image,
img.dave-received-image, */
video.dave-received-video,
video.dave-sent-video {
    position: relative;
    width: 60%;
    max-width: 250px;
    max-height: 250px;
    height: 140px;
}

a.dave-received-image-contaier,
a.dave-sent-image-contaier {
    display: flex;
}

img.dave-sent-image,
img.dave-received-image {
    position: relative;
    width: 100%;
    max-width: 250px;
    max-height: 250px;
    min-width: 250px;
    min-height: 250px;
}
video.dave-sent-video{
    border: 5px solid #DCF7C5;
    border-radius: 10px;
}
video.dave-received-video{
    border: 5px solid #FFF;
    border-radius: 10px;
}
audio.dave-sent-audio::-webkit-media-controls-panel{
    background-color: #DCF7C5;
}

.dave-doc-message-container{
    max-width: 250px;
    background-color: #fff;
    text-decoration: none;
    padding: 10px 12px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    gap: 10px;
    overflow: hidden;
}
.dave-doc-message-container .doc-file-extension{
    text-transform: uppercase;
    font-weight: bold;
    color: #333;
    background-color: rgb(0 0 0 /20%);
    height: 40px;
    width: 40px;
    display: grid;
    font-size: 0.9rem;
    place-items: center;
    flex-shrink: 0;
    border-radius: 5px;
}
.dave-doc-message-container .doc-filename{
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    color: #333;
}
.dave-doc-message-container.sent{
    background-color: #DCF7C5;
}
.dave-doc-message-container.sent .doc-file-extension{
    background-color: rgb(0 0 0 /15%);
}