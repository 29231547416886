.dave-add-cus-notification-container {
    display: flex;
    justify-content: flex-end;
}

nav.navbar.bg-body-tertiary {
    height: 70px;
}
.header-status-container{
    display: none;
}

.dave-popup-message-container{
    display: none;
} .message-popup{
    display: none;
}

@media (max-width:1100px){
    nav.navbar.bg-body-tertiary{
        background-color: #008069 !important;
        border-radius: 0 0 30px 30px;
        box-shadow: 0px 5px 10px 0px #00000040;
        padding-top: 20px;
        height: 100px;
        flex-shrink: 0;
    }
    nav.navbar div.dave-logo-container{
        display: none;
    }
    .header-status-container{
        display: flex;
        flex: 1;
        gap: 10px;
    }
}