.dave-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    z-index: 10;
    align-items: center;
    background: rgba(27, 27, 27, 0.5);
}

.dave-loader-position {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}
.dave-loader .dave-loader-img {
  /* max-width: 100px; */
  max-width: 500px;
  object-fit: contain;
}
