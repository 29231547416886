.dave-add-walkin-customer-container {
    background: #fff;
    border-radius: 10px;
    height: 80vh;
    width: 500px;
}
.dave-dropdown-logo-img{
    height: 0.9rem;
}

form.dave-add-walk-in-customer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly; */
    /* height: 100%; */
    z-index: 1;
    background: #fff;
    border-radius: 10px;
    height: fit-content;
    width: 500px;
    max-height: 70vh;
    overflow: auto;
    border: 3px solid #000;
    position: relative;
}

.dave-add-walk-in-customer .form-group {
    width: 90%;
    margin: 20px 0 0 0;
}

.dave-add-customer-popup-container .form-group .form-control {
    border: 2px solid #000;
    height: 40px;
    border-radius: 10px;
} 

.form-group label {
    color: #000;
}

button.dave-dropdown {
    background: #008069 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 37px;
    /* background: linear-gradient(90deg, #2D309A 0%, #4F63F8 100%); */
    border: none;
    border-radius: 0 7px 7px 0;
    position: absolute;
    right: 0;
    height: 100%;
    pointer-events: none;
}

.dave-dropdown-container {
    position: relative;
    border: 2px solid #000;
    border-radius: 10px;
    display: flex;
    flex-wrap: nowrap;
}
.dave-dropdown-container .calendarIcon{
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(calc(-50% + -0.5px));
    pointer-events: none;
}
.dave-dropdown-container > input[type="date"]{
    opacity: 0;
    pointer-events: none;
}
.dave-dropdown-container .date-pretext{
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
    pointer-events: none;

}
.dave-dropdown-container .form-control {
    /* border: 2px solid #008069 !important; */
    box-shadow: none;
}

button.dave-add-customer-submit {
    background-color: #008069 !important;
    /* background: linear-gradient(90deg, #2D309A 0%, #4F63F8 100%); */
    border: 2px solid #000 !important;
    border-radius: 10px;
    width: 40%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 15px 0 10px 0;
}

.dave-dropdown-container select {
    background: #fff;
}

img.dave-close-add-customer-form {
    position: sticky;
    height: 20px;
    width: 20px;
    top: 5px;
    right: 5px;
    align-self: flex-end;
}

input#dave-customer-vehicle-number {
    text-transform: uppercase;
}

.container-title{
    display: none;
}

@media (max-width:1100px){
    .container-title{
        display: block;
        font-size: 2rem;
        margin-bottom: -20px;
        padding: 2rem 5rem;
        padding-top: 2.5rem;
    }
    form.dave-add-walk-in-customer{
        padding: 1rem 5rem;
        width: 600px;
    }
    button.dave-add-customer-submit{
        width: auto;
        gap: 10px;
        padding: 12px 1.5rem;
        border-radius: 5px;
        border: 2px solid #000 !important;
        margin: 2.5rem;
    }

    .dave-add-customer-popup-container .form-group .form-control{
        border-radius: 5px;
    }
    .dave-dropdown-container{
        border-radius: 5px;
    }
    button.dave-dropdown{
        border-radius: 0 3px 3px 0;
    }
    img.dave-close-add-customer-form{
        right: 0;
        top: 0;
        transform: translateX(57px);
    }
}