.dave-tickets-container {
    background: #fff;
    padding: 20px;
    /* margin-right: 10px; */
}

.dave-no-ticket-container {
    display: flex;
    justify-content: center;
    height: 100%;
    /* color: rgb(45, 52, 148); */
    color : #008069
}

@media (max-width:1100px){
    .dave-tickets-container{
        flex: 1;
        padding: 0;
    }
}