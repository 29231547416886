.dave-close-ticket-container {
    position: relative;
    flex: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background: #F3F3F3;
    border-radius: 0 0 10px 10px;
    width: 100%;
    padding-top: 20px;
}

button.dave-ticket-reassign-bt {
    /* background: linear-gradient(90deg, #2D3494 0%, #4F63F8 100%); */
    background: #008069 !important;
    color: #fff !important;
    width: 150px;
}

button.dave-ticket-reassign-cancel-btn {
    background: #737373 !important;
    /* background: linear-gradient(90deg, #C61616 0%, #F14F4F 100%); */
    color: #fff !important;
    width: 150px;
}

.dave-close-ticket-message-container {
    display: flex;
    align-items: center;
    width: 35%;
    text-align: center;
}

.dave-close-ticket-action-container {
    flex: none;
    display: flex;
    width: 50%;
    max-width: 420px;
    justify-content: space-evenly;
}

.dave-smre-advisior-reassign-container,
.dave-service-advisior-reassign-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dave-smre-advisior-reassign-container span,
.dave-service-advisior-reassign-container span {
    /* color: #2D3494; */
    color: #000;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
}

.dave-smre-advisior-reassign-container .dave-dropdown-container,
.dave-service-advisior-reassign-container .dave-dropdown-container {
    width: 100%;
    display: flex;
    /* border: 2px solid #008069; */
    outline-offset: -2px;
    border: none;
    background: #E5E5E5;
    border-radius: 24px;
}

.dave-service-advisior-reassign-container button.dave-dropdown {
    /* background: linear-gradient(90deg, #2D3494 0%, #4F63F8 100%); */
    background: #008069;
    border: none !important;
    border-radius: 0 24px 24px 0;
}

.dave-dropdown-container select {
    border: none !important;
    border-radius: 24px;
    background: #E5E5E5;

}
.dave-dropdown-container select.form-control.border-black{
    border: 2px solid #008069 !important;
}