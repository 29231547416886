.dave-active-chat-container {
    position: relative;
    flex: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(224, 215, 208);
    border-radius: 10px;
    /* margin-right: 10px; */
}

/* .dave-active-chat-message-container {
    position: relative;
    height: calc(91vh - 170px);
    width: 100%;
} */

.flex-column {
    width: 100%;
}

button#dave-reopen-ticket {
    background: #008069 !important;
    border-radius: 12px;
    color: #fff !important;
    border: none;
    margin: 0 10px;
}

.dave-no-active-message-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 25px;
}
.closed-ticket-info{
    padding: 12px;
    background-color: #fff;
    width: 100%;
    text-align: center;
    font-weight: bold;
} 
 
.dave-reopen-action {
    width: 100px;
    height: 50px;
    background: #008069 !important;
    border: none;
    border-radius: 24px;
    margin: 10px 0;
    color: #fff !important;
    font-style: normal;
    font-size: large;
    line-height: 28px;
    text-align: center;
    outline: none;
}

div#dave-popup-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    background: #fff;
    height: 100%;
    width: 100%;
    z-index: 1;
    text-align: center;
}

div#dave-job-reopen-action-container {
    width: 300px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


@media (max-width:1100px){
    .dave-active-chat-container{
        flex: 1;
    }
}