.dave-signin-container {
    display: flex;
    position: relative;
    /* top: 15%; */
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.dave-user-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* background-image: url("../../images/Rectangle.png"); */
}

input.dave-username,
input.dave-username:focus-visible,
input.dave-password,
input.dave-password:focus-visible {
    background: #F3F5FC;
    margin: 5px 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #737373;
    padding: 5px 15px;
    border: 2px solid #008069;
    border-radius: 10px;
    outline: none;
    /* background-image: url("../../images/Rectangle.png"); */
    background-size: 100% 100%;
    height: 60px;
}

button.dave-signin-button {
    padding: 0;
    border: none;
    height: 80px;
    width: 200px;
    background-color: #008069;
    border-radius: 20px;
    display: grid;
    place-items: center;
}
button.dave-signin-button.btn.btn-sm:hover{
    background-color: #008069;
}
.dave-signin-btn-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

img.dave-signin-button {
    /* width: -webkit-fill-available; */
    /* width: 100%; */
}
.dave-homepage-container {
    display: grid;
    flex-direction: column;
    /* justify-content: space-evenly; */
    justify-items: center;
    grid-template-rows: 100px 1fr 1fr;
    align-items: center;
    min-height: 100vh;
}

/* img.dave-footer-logo-img {
    width: -webkit-fill-available;
    max-width: 1000px;
} */

img.dave-main-logo-img {
    width: -webkit-fill-available;
}

.dave-logo-container {
    position: relative;
    top: 10%;
}

.dave-footer-container {
    /* position: fixed; */
    bottom: 0;
    display: flex;
    justify-content: center;
    /* max-height: 35%; */
    /* height: 35vh; */
    flex-direction: column;
    align-items: center;
}

.dave-footer-container > img{
    height: 94%;
}

span.dave-login-message {
    /* position: fixed; */
    bottom: 40%;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    text-transform: capitalize;
    color: #737373;
}

.dave-login-error-message {
    color: red;
    text-transform: uppercase;
}

@media (max-width:1100px){
    .dave-homepage-container{
        display: flex;
        justify-content: space-between;
    }
    .dave-logo-container{
        width: 80%;
        margin-top: -2rem;
        margin-bottom: 2rem;
        display: flex;
    }
    img.dave-main-logo-img{
        width: 60vw;
        margin: auto;
    }
    button.dave-login.btn{
        border-radius: 20px;
    }
    button.dave-login.btn .icon-container{
        border-radius: 0 20px 20px 0;
    }
    span.dave-login-message{
        margin-bottom: 2rem;
    }
    .dave-signin-btn-container{
        width: 160px;
    }
    .dave-signin-container{
        gap: 1rem;
    }
    .dave-user-details-container{
        gap: 1rem;
    }
}