
.dave-upload-container.col {
    flex: none;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 80px;
    border: 2px solid rgb(0, 128, 105);
    border-radius: 10px;
    margin: 0 0 5px 0;
    position: absolute;
    bottom: 90px;
}

.dave-upload-file {
    width: 90%;
}
.select-file-preview-container{
    display: flex;
    padding: 20px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.select-file-preview-container span{
    background-color: #f1f3f5;
    border-radius: 5px;
}
.select-file-preview-container .select-file-filename{
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.9rem;
    font-weight: bold;
    text-overflow: ellipsis;
    padding: 5px 10px;
}
.select-file-preview-container .close-file-btn{
    padding: 5px 7px;
    cursor: pointer;
}
.select-file-preview-container .close-file-btn img{
    height: 1rem;
    object-fit: contain;
}

.file_error_modal_container{
    background-color: rgb(0 0 0 /20%);
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.file_error_modal{
    background-color: #f1f3f5;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 20px -5px rgb(0 0 0 /70%);
    border: 2px solid #000;
    animation: zoom-in 0.3s;
}
.file_error_modal button{
    margin-left: auto;
    display: block;
    padding: 5px 20px;
    border-radius: 100px;
    border: none;
    background-color: rgb(0, 128, 105);
    color: #fff;
}

.file_error_modal button:hover{
    background-color: rgb(29, 153, 130);
}

@keyframes zoom-in {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}