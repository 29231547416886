.dave-chat-status-container {
    flex: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    background: #008069;
    width: 14.5%;
}

img.dave-loader-gif {
    width: 50%;
}

@media (max-width:1100px){
    .dave-chat-status-container{
        display: none;
    }
}